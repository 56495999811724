import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Link from "../register/Link";
import Title from "../common/Title";
import ScaledButton from "../common/ScaledButton";
import { Base64 } from "js-base64";
import { handleRequest } from "../../services/user.service";

const PAGES = {
  REJECT: "reject",
  APPROVE: "approve",
  SUCCESS: "success",
  PROCESSING: "processing",
  ERROR: "error",
};

const defaultHeaderImg = "./assets/email/Mail_Header_RequestPic.png";

function decodeStr(str) {
  if (str === null) return null;
  try {
    return Base64.decode(str);
  } catch {
    return null;
  }
}

function getUrlParam(searchString, parameter, isEncoded = false) {
  const search = new URLSearchParams(searchString);
  return isEncoded ? decodeStr(search.get(parameter)) : search.get(parameter);
}

function CloseWindowLink() {
  return (
    <span style={{ display: "block", marginTop: "2rem" }}>
      You can now close this window manually or click{" "}
      <Link onClick={() => window.close()}>here</Link> to close.
    </span>
  );
}

function DisplayMessage({ userData }) {
  return (
    <DisplayMessageContainer>
      <BlockTitle color="#fff" style={{ display: "block", textTransform: "inherit" }}>
        You have successfully &quot;<u>{userData.status}</u>&quot; PicGen user registration request
        for the following user:
      </BlockTitle>

      <br />

      <BlockTitle style={{ textTransform: "inherit" }}>Name: {userData.name}</BlockTitle>
      <BlockTitle style={{ textTransform: "inherit" }}>Email: {userData.email}</BlockTitle>

      <CloseWindowLink />
    </DisplayMessageContainer>
  );
}

function ActionBar({ action }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        columnGap: "1rem",
        marginTop: "1rem",
      }}
    >
      <ScaledButton label="Cancel" color="black" action={() => window.close()} />

      <ScaledButton label="Confirm" action={action} />
    </div>
  );
}

export default function HandleRequest() {
  const { token } = useParams();

  const userData = useRef(null);

  const [approvePath, rejectPath] = [
    getUrlParam(window.location.search, "approve"),
    getUrlParam(window.location.search, "reject"),
  ];

  const [page, setPage] = useState(
    approvePath ? PAGES.APPROVE : rejectPath ? PAGES.REJECT : PAGES.ERROR,
  );

  const submitRequest = async (action) => {
    setPage(PAGES.PROCESSING);

    try {
      const res = await handleRequest(action === "approve" ? approvePath : rejectPath, token, {});

      if (res.status === 200) {
        userData.current = res.data.data;

        setPage(PAGES.SUCCESS);
      } else {
        console.error(res.data.message);
        setPage(PAGES.ERROR);
      }
    } catch (e) {
      console.error("Something went wrong. Please try again later.");
      console.error(e);
      setPage(PAGES.ERROR);
    }
  };

  return (
    <Container>
      {page === PAGES.ERROR && (
        <>
          <Box>
            <div style={{ textAlign: "center" }}>
              <PrimaryText>An error occurred!</PrimaryText>
              <Title color="#fff">Please try again after some time.</Title>

              <CloseWindowLink />
            </div>
          </Box>
        </>
      )}

      {page === PAGES.PROCESSING && (
        <>
          <HeaderImg src={defaultHeaderImg} alt="header" />

          <Box>
            <Title color="#fff">Please wait while we process your request...</Title>
          </Box>
        </>
      )}

      {page === PAGES.APPROVE && (
        <>
          <HeaderImg src={defaultHeaderImg} alt="header" />

          <Box>
            <div>
              <PrimaryText style={{ color: "lightgreen" }}>Approve user?</PrimaryText>

              <Title color="#fff" style={{ textTransform: "none" }}>
                Click on the &quot;confirm&quot; button below to process the approval request for
                the user.
              </Title>

              <br />

              <Title color="#fff" style={{ textTransform: "none" }}>
                Click on the &quot;cancel&quot; button to close this window, the registration
                request will be kept on hold until you either accept or reject it!
              </Title>

              <br />

              <ActionBar action={() => submitRequest("approve")} />
            </div>
          </Box>
        </>
      )}

      {page === PAGES.REJECT && (
        <>
          <HeaderImg src={defaultHeaderImg} alt="header" />

          <Box>
            <div>
              <PrimaryText style={{ color: "#ff4747" }}>Reject user?</PrimaryText>

              <Title color="#fff" style={{ textTransform: "none" }}>
                Click on the &quot;confirm&quot; button below to reject the registration request.
              </Title>

              <br />

              <Title color="#fff" style={{ textTransform: "none" }}>
                Click on the &quot;cancel&quot; button to close this window, the registration
                request will be kept on hold until you either accept or reject it!
              </Title>

              <br />

              <ActionBar action={() => submitRequest("reject")} />
            </div>
          </Box>
        </>
      )}

      {page === PAGES.SUCCESS && <DisplayMessage userData={userData.current} />}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: #1e2022;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PrimaryText = styled.h1`
  font-family: "Overpass-Medium";
  text-transform: uppercase;
  margin: 0;
`;

const Box = styled.div`
  display: flex;
  margin: 5rem 0;
  padding: 0 1rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    width: 60%;
  }
`;

const DisplayMessageContainer = styled.div`
  text-align: center;
  margin-top: 4rem;
`;

const HeaderImg = styled.img`
  object-fit: contain;
  width: 100%;
`;

const BlockTitle = styled(Title)`
  display: block;
`;

DisplayMessage.propTypes = {
  userData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }),
};

ActionBar.propTypes = {
  action: PropTypes.func.isRequired,
};
