import Cookies from "js-cookie";

export function handleError(error) {
  console.error("API call failed");
  console.error(error.response);
  throw error.response?.data?.message || "";
}

export const BASE_URL = process.env.API_URL;
export const MAX_TIMEOUT_WAIT = 5000;
export const IMAGEGEN_URL = process.env.IMAGEGEN_URL ? process.env.IMAGEGEN_URL : "";

// TODO: Retrieve this brand map from backend end point
export const BRAND_MAP = new Map([
  ["CHEVROLET US", 1],
  ["CHEVROLET GLOBAL", 2],
  ["GMC", 3],
  ["CADILLAC", 4],
  ["BUICK", 5],
]);

export function getToken() {
  return Cookies.get("picgen");
}

function findElementByKey(arr, key, val) {
  return arr.find((element) => {
    return element[key] === val;
  });
}

const mapAuthenticate = (v3resp) => {
  const user = v3resp.data.user;
  const token = v3resp.data.token;
  const v2data = {
    token,
    id: user.id,
    username: user.username,
    reg_date: user.createdAt,
    last_login: user.updatedAt,
    role: user.roleId,
    region: "all",
    email_verify: "",
    isActive: user.active,
    isDebug: user.role.scopes.includes("debug"),
    isAdmin: user.role.scopes.includes("admin"),
  };

  return v2data;
};

const mapGetModels = (v3resp, initialData) => {
  const models = v3resp.data.models;
  const years = [];
  const brands = [];
  const result = [];

  models.forEach((model) => {
    const year = Number(model.year.name);
    if (!years.includes(year)) {
      years.push(year);
    }

    if (!brands.includes(model.brand.name)) {
      brands.push(model.brand.name);
    }
  });

  brands.sort();

  years.forEach((year) => {
    const yModels = models.filter((model) => Number(model.year.name) === year);
    const yBrandModels = brands.map((brand) => {
      const { id: brandId } = findElementByKey(initialData.brands, "name", brand);

      const ybModels = yModels.filter(
        (ymodel) => ymodel.brand.name.toUpperCase() === brand.toUpperCase(),
      );
      const reducedModels = {};

      ybModels.forEach((yb) => {
        const modelname = yb.model;
        const id = yb.id;
        const model_folder_name = yb.modelFolderName;

        if (!Object.keys(reducedModels).includes(modelname)) {
          const submodels = [];

          if (yb.submodel && yb.submodel.toUpperCase() !== "NA") {
            submodels.push({
              ...yb,
              id,
              model_folder_name,
              submodel: yb.submodel,
            });
          }

          const model = {
            ...yb,
            id,
            model_folder_name,
            submodels,
            model: modelname,
          };

          reducedModels[modelname] = model;
        } else {
          reducedModels[modelname].submodels.push({
            id,
            model_folder_name,
            submodel: yb.submodel,
          });
        }
      });

      const mergedModels = Object.keys(reducedModels).map((k) => reducedModels[k]);

      return {
        brand,
        id: brandId,
        models: mergedModels,
      };
    });

    result.push({
      year,
      brands: yBrandModels,
    });
  });

  result.sort((a, b) => a.year - b.year);

  const v2data = {
    data: { result },
  };

  return v2data;
};

const mapGetProjectJson = (v3resp) => {
  const v2data = {
    data: {
      id: v3resp.data.id,
      projectId: v3resp.data.projectId,
      project: v3resp.data.project,
      result: v3resp.data.json,
    },
  };

  return v2data;
};

export const mapApiV3toV2 = {
  authenticate: mapAuthenticate,
  getModels: mapGetModels,
  getProjectJson: mapGetProjectJson,
};
